
/* eslint-disable @typescript-eslint/camelcase */
// @ts-nocheck
import { Product } from '@/interfaces/product.interface'
import { GetTicketInformation } from '@/services/public.service'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { shareTicketByMail, generateTicket } from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'

@Component
export default class QRStatus extends Vue {
  @Prop() readonly headerText!: string
  public productName = 'Nombre del producto'
  public productPrice!: number
  public productLink = ''
  public product!: Product
  public share_message = '23456789'
  public ticket = null

  async getTicketInfo() {
    const {
      data: { data },
    } = await GetTicketInformation(this.$route.params.productId)

    this.ticket = data

    this.productName = data.product.name
    this.productPrice = data.product.price
    this.productLink = `${process.env.VUE_APP_DOMAIN_ECOMMERCE +
      '/ticket-verification'}/${data.uuid}`
    this.product = data.product

    this.share_message = this.messageToShare
  }

  mounted() {
    this.getTicketInfo()
  }

  public showModal = false

  showCloneModal() {
    this.showModal = true
  }

  closeModal() {
    this.showModal = false
  }

  async cloneTicket() {
    const loading = this.$loading.show()
    try {
      let newTicket
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const createTicketData: CreateTicketData = {
        product_id: this.ticket.product.id,
        quantity_persons: this.ticket.quota,
        payment_status: 1,
        payment_method: 'cash',
        reference: this.ticket.reference,
      }
      newTicket = await generateTicket(createTicketData)
      this.closeModal()
      loading.hide()
      this.$router.push({
        name: 'Compartir Link',
        params: { productId: newTicket.uuid },
      })
      this.productLink = `${process.env.VUE_APP_DOMAIN_ECOMMERCE}/ticket-verification/${newTicket.uuid}`;
      Vue.$toast.success('Ticket creado correctamente!');
    } catch (error) {
      if (error.response.status === 422) {
        Vue.$toast.warning(error.response.data.message)
        this.closeModal()
        loading.hide()
        return
      }
      Vue.$toast.error(ErrorMessages.COMMON)
      this.closeModal()
      loading.hide()
    }
  }

  copyToClipBoard() {
    const htmlContent = `
      <p>${this.share_message}</p>
      <p><a href="${this.productLink}" target="_blank" style="color: #007aff; text-decoration: underline;">${this.productLink}</a></p>
    `
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlContent
    document.body.appendChild(tempElement)

    const range = document.createRange()
    range.selectNode(tempElement)
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)

    document.execCommand('copy')
    document.body.removeChild(tempElement)

    Vue.$toast.info('Mensaje copiado al portapapeles!')
  }

  toGenerateLink() {
    this.$router.push({
      name: 'Generar Link',
      params: { productId: this.product.uuid },
    })
  }

  toSellerMenu() {
    this.$router.push({ name: 'Mis productos' })
  }

  whatsappShare() {
    window.open(
      `https://wa.me?text=${encodeURIComponent(this.productLink)} ${
        this.share_message
      }`,
      '_blank'
    )
  }

  get messageQuota() {
    return (
      'Link habilitado para registrar ' +
      this.ticket.quota +
      ' asistente/s. ' +
      this.ticket.product.share_message
    )
  }

  get messageToShare() {
    return this.ticket.product.min_age && this.ticket.product.min_age != 0
      ? this.messageQuota.concat(
          '. ',
          'Producto habilitado para mayores de ' +
            this.ticket.product.min_age +
            ' años'
        )
      : this.ticket.product.share_message
  }

  showShareTicketModal() {
    this.$refs['share-modal'].show()
  }

  hideShareTicketModal() {
    this.$refs['share-modal'].hide()
  }

  async onSubmitShareTicket(event) {
    event.preventDefault()

    try {
      const receiverArray = []

      if (event.target.elements.to.length) {
        event.target.elements.to.forEach(element => {
          if (element.value) {
            const receiver = {
              value: element.value,
            }
            receiverArray.push(receiver)
          }
        })
      } else {
        if (event.target.to.value) {
          const receiver = {
            value: event.target.to.value,
          }
          receiverArray.push(receiver)
        }
      }

      const body = {
        message: this.messageToShare,
        link: this.productLink,
        ticket_id: this.ticket.id,
        receiver: JSON.stringify(receiverArray),
      }

      const response = await shareTicketByMail(body)

      if (response.status == '200') {
        this.hideShareTicketModal()
        Vue.$toast.success(`Correo enviado correctamente`)
      } else {
        Vue.$toast.error('Ha ocurrido un error!')
      }
    } catch (e) {
      console.error(e)
      Vue.$toast.error('Ha ocurrido un error!')
    }
  }
}
